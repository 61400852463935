import { AppStoreButton } from "react-mobile-app-button";
import React from "react";

export const AppleStore = () => {
  const APKUrl =
    "https://apps.apple.com/tr/app/qizzy-app/id6468040906";
  return (
    <div>
      <AppStoreButton
        url={APKUrl}
        theme={"dark"}
        width={200}
        className={"custom-style"}
      />
    </div>
  );
};

export default AppleStore;