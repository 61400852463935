import React from "react";
import PdfText from "./PdfText";

const SSS = () => {



  return (
    <div className="w-screen h-full min-h-screen flex flex-col justify-center items-center bg-gray-200 ">
      <div className="h-full w-11/12 lg:p-12 md:w-6/12 min-h-screen shadow-md bg-white p-5 mt-3 mb-3">
        <PdfText
          title="Qizzy App Nedir?"
          subtitle="Qizzy app yapay zeka destekli bir soru üretme, çözme ve değerlendirme yapma uygulamasıdır. Herhangi bir belgeyi yükleyerek, bilginizi test etmek istediğiniz herhangi bir metni kopyalayarak ya da elinizdeki bir dokümanı OCR ile taratıp soru üretebilirsiniz. Üretilen soruları çözerek bilginizi test edebilirsiniz."

        />

        <PdfText
          title="Nasıl Çalışır?"
          subtitle="Qizzy app yapay zeka alt yapısına sahiptir. Herhangi bir dokümandaki tüm veriler taranarak ilgili dokümandan bilginiz ölçecek soru setlerini hazırlar. Size uygun formatta quizler oluşturur. Siz soruları cevapladıkça puanlarınızı hesaplar ve gelişiminizi takip eder."

        />


        <PdfText
          title="Premium Üyelik Nedir?"
          subtitle="Mevcut üyelerimiz günlük olarak belirli sayıda soru üretimi ve çözümlerine ulaşabiliyorken premium üyelik günlük soru limitlerini kaldırmanızı ve dilediğiniz sayıda doküman taramanızı sağlar."

        />


        <PdfText
          title="Üyelik iptali?"
          subtitle="Üyelik iptalini profilinizdeki haftalık plan sekmesinden kolaylıkla gerçekleştirebilirsiniz."

        />


        <PdfText
          title="Nasıl Soru Üretirim?"
          subtitle="Qizzy app’te 4 farklı şekilde soru üretimi yapılabilir 1. Dosya yükleme ile (.pdf, .docx, .pptx, .rtf, .txt), 2. Kopyala yapıştır yöntemi ile, 3. OCR ile, 4. Youtube ve wikipedia linkleri ile"

        />


        <PdfText
          title="Grup Quizi Nedir?"
          subtitle="Grup quizi arkadaşlarınla yarışabilmek için kullanabileceğin bir özellik, soru üret, arkadaşlarınla quiz kodunu paylaş, aynı soruları çöz ve puan durumunu gör."

        />


        <PdfText
          title="Leaderboard nasıl çalışır?"
          subtitle="Leaderboard aynı quizi yanıtlayan kullanıcıların aldığı puana göre sıralanmasıdır. Öğretmen olarak öğrencilerinizi aynı anda quiz yapabilir ya da kendinizi birlikte geliştirmek istediğiniz arkadaşınızla puanlarınızı karşılaştırabilirsiniz."

        />

        <PdfText
          title="Topladığım puanlar ne işe yarar?"
          subtitle="Topladığın puanlar ile qizzy appin tüm kullanıcıları arasında bir yarışa dahil olarak, haftalık ve aylık ödüllere ulaşabilirsin."

        />

        <PdfText
          title="Dosyalarım nasıl çalışır?"
          subtitle="2 farklı dosyalama seçeneği ile dilersen sadece sana ait özel dosyalar oluşturup kendini takip edebilirsin, dilersen de herkese açık soru dosyalarıyla sınırsız sayıda quizlere ulaşabilirsin."

        />





      </div>




    </div>

  )
}
export default SSS;