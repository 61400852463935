import React, { useState } from "react";
import Fade from 'react-reveal/Fade';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
    const [t, i18next] = useTranslation("global");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [topic, setTopic] = useState("");
    const [message, setMessage] = useState("");

    const nameHandler = (e) => {
        setName(e.target.value)
    }

    const mailHandler = (e) => {
        setEmail(e.target.value)
    }

    const topicHandler = (e) => {
        setTopic(e.target.value)
    }

    const messageHandler = (e) => {
        setMessage(e.target.value)
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }


    const form = useRef();

    const recaptchaRef = useRef(null);

    const [valid, setValid] = useState(false);

    const [wait, setWait] = useState(false)

    const onChange = (value) => {
        if (value != null) {
            setValid(true)

        }
        else {
            setValid(false)
        }
    }

    const onExpire = (value) => {
        setValid(false)
    }

    const resetRecaptcha = () => {
        if (recaptchaRef.current) {
            setValid(false)
          recaptchaRef.current.reset();
        }
      };







    const sendEmail = async (e) => {
        e.preventDefault();
        setWait(true)

        if (name && email && message && topic) {
            if (!isValidEmail(email)) {
                alert('Geçerli bir email girin.')
            }
            else {
                await emailjs.sendForm('service_646cs0f', 'template_jyckoa6', form.current, 'GIhRnOyHMQQhH_Ju_')
                    .then((result) => {
                        alert("Talebiniz tarafımıza ulaştı. En kısa sürede cavap vereceğiz.")
                    }, (error) => {
                        console.log(error);
                    });
                    resetRecaptcha();
                    setName("")
                    setEmail("")
                    setTopic("")
                    setMessage("")

            }


        }
        else {
            alert("Please fill the form to submit !")


        }

        setWait(false)




    };

    return (


        <form ref={form} onSubmit={sendEmail} className=" rounded-lg  dark:bg-gray-800 bg-white shadow-lg">
            <Fade>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5  items-center justify-center p-10 ">
                    <input type="text" value={name} onChange={nameHandler} name="user_name" placeholder={t('contact.name')} className="dark:bg-gray-600 dark:text-white text-slate-800 border border-gray-300 p-3.5 rounded-lg caret-slate-800 focus:outline-none" />
                    <input type="email" value={email} onChange={mailHandler} name="user_mail" placeholder={t('contact.email')} className="dark:bg-gray-600 dark:text-white text-slate-800 border border-gray-300 p-3.5 rounded-lg caret-slate-800 focus:outline-none" />

                </div>
            </Fade>
            <div className="grid grid-cols-1 gap-5 items-center justify-center pl-10 pr-10 pb-10">
                <Fade ><input type="text" value={topic} onChange={topicHandler} name="topic" placeholder={t('contact.subject')} className="dark:bg-gray-600 dark:text-white text-slate-800 border border-gray-300 p-3.5 rounded-lg caret-slate-800 focus:outline-none" /></Fade>
                <Fade ><textarea rows="5" type="text" value={message} onChange={messageHandler} name="message" placeholder={t('contact.message')} className="dark:bg-gray-600 dark:text-white text-slate-800 border border-gray-300 p-3.5 rounded-lg caret-slate-800 focus:outline-none" /></Fade>
                <div className="h-10"></div>
                <Fade>
                    <ReCAPTCHA className="m-0 flex items-center justify-center"
                        ref={recaptchaRef}
                        sitekey="6LeHwigoAAAAALp8TfVvVm7s65Yf_n_dsoV_wFz9"
                        onChange={onChange}
                        onExpired={onExpire}

                    />
                </Fade>
                <>
                    {
                        valid == true && wait == false ? <Fade> <input type="submit" className="p-3 bg-purple-300 w-64 m-auto text-xl font-bold text-white rounded-full border" value={t('contact.submit')}></input></Fade> : <div></div>

                    }
                </>
            </div>





        </form>


    );
}

export default ContactForm;