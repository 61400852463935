import React from "react";
import PdfText from "./PdfText";

const Privacy = () => {
    return (
        <div className="w-screen h-full min-h-screen flex flex-col justify-center items-center bg-gray-200">
            <div className="h-full w-11/12 lg:p-12 md:w-6/12 min-h-screen shadow-md bg-white p-5 mt-3 mb-3 ">
                <PdfText title="Privacy Policy"
                    subtitle="As Qizzy App, we respect your personal privacy and ensure the safe protection of your data. By downloading and using the Qizzy app, you agree to this privacy policy completely. Therefore, please review our privacy policy before using our application." />

                <p className="font-normal text-base pb-5">Qizzy App can update the privacy policy at any time. Agreeing this privacy policy means agreeing with this probability too. If you disagree with the policy, you should cancel your membership and stop using the Qizzy app.</p>

                <p className="font-normal text-base pb-5"><span className="font-bold text-base">'We'</span> refers to Qizzy App.</p>

                <p className="font-normal text-base pb-5"><span className="font-bold text-base">‘You’</span> refers to a user or premium user.</p>

                <p className="font-normal text-base pb-5"><span className="font-bold text-base">'User'</span> refers to each user that downloaded the app.</p>

                <p className="font-normal text-base pb-5"><span className="font-bold text-base">'Premium User'</span> refers to users who have subscribed to the Qizzy App.</p>

                <p className="font-normal text-base pb-5">There is only a difference between Premium User and User: While Premium Users can upload unrestricted files as long as their subscription, Users can only upload a limited number of files.</p>

                <PdfText title="I. Your Documents"
                    subtitle="The documents you create/upload using the application are only stored in your device and in MongoDB that we use for data storage and are not sent to any third party unless you want to share the documents or transfer them to other applications. When you share your documents, the documents will be public. If you share a folder that contains more than one file, we convert the files into zip files and the zipped files are stored on AWS S3." />


                <h1 className="font-bold text-base pb-5">II. Data we collect</h1>

                <ul className="px-6 pb-5 text-base font-normal list-disc">
                    <li>Your device ID.</li>
                    <li>Data information that you upload, data information that you create with using the app and information we obtain through your use of our products and services.
                    </li>
                    <li>E-mail address (this information will only be collected if you subscribe to Qizzy App).</li>
                </ul>

                <PdfText title="III. How we process the data collected"
                    subtitle="We may only show ads for limited users (users that use the free version) for IOS and Android. We collect click rates on ads and do not share them with any other third parties" />

                <p className="font-normal text-base pb-5">We use Firebase Analytics to track and analyze usage data, such as how often each of the features in the app is used. We regularly conduct these follow-up and analysis to see the features that need to be edited or added/removed in the application. Data is anonymized and then performance monitoring, setup and IP-related events are stored for 30 days, defined performance data are stored for 90 days. We do the tracking and analysis of the crash logs you may encounter through Firebase Crashlytics. This data is anonymized and stored for up to 90 days.</p>

                <PdfText title="IV. Permissions we ask"
                    subtitle="We ask permission;" />

                <ul className="px-6 pb-5 text-base font-normal list-disc">
                    <li>to access your device storage to read and manage your documents.</li>
                    <li>to access your camera and album so that you can scan files and upload files from your device.
                    </li>
                    <li>to push notifications on your device.</li>
                    <li>to access your device ID. In order for you to use the application as a User or a Premium User, we must match your device ID with features.</li>
                </ul>

                {/* The Table Will Come Here */}
                <h1 className="font-bold text-base pb-5">V. Sub-processors</h1>
            
                <table className="table-auto border-collapse border border-black text-center">
                <caption class="caption-top text-start pb-2">
                Table V.I: Sub-processors
                </caption>
                <tr>
                        <th className="border border-black p-3">Name</th>
                        <th className="border border-black p-3">Purpose</th>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Sentry</td>
                        <td className="border border-black p-3">Log Management</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Onesignal</td>
                        <td className="border border-black p-3">Push Notification</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">AWS</td>
                        <td className="border border-black p-3">Production Environment</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Digitalocean</td>
                        <td className="border border-black p-3">Test Enviroment</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Mongo Cloud </td>
                        <td className="border border-black p-3">Database</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Apple Pay</td>
                        <td className="border border-black p-3">Payment</td>
                    </tr>

                    <tr>
                        <td className="border border-black p-3">Google Pay</td>
                        <td className="border border-black p-3">Payment</td>
                    </tr>
                </table>
                <div className="pb-5"></div>

                <PdfText title="VI. Cancellation of Subscription"
                    subtitle="If you are a Premium User, you can cancel your subscription whenever you want. After you cancel your subscription, you can use the app as Premium User till the end of your subscription date. We state that we do not refund any subscription fees that are paid once. If you would like to require a full refund, you should contact Customer Support to have more information." />

                <PdfText title="VII. Uninstalling the App"
                    subtitle="If the user uninstalls the application, the previously installed data continues to be stored with the device ID. You can contact us at [info@qizzy.io] to cancel your account and delete your information. We will respond to your request as soon as possible." />

                <PdfText title="VIII. Restrictions"
                    subtitle="Qizzy app is not intended for children. If you are a parent and you recognize that your child has provided us with personal data, please contact us. In such cases, we will delete those data from our systems." />

                <h1 className="font-bold text-base pb-5">IX. Divulgence of Information</h1>

                <ul className="px-6 pb-5 text-base font-normal list-disc">
                    <li>Due to computer virus, trojan horse and hacker attack</li>
                    <li>Because of, you share your password with others, allow others to get your password, or share a registered account with others
                    </li>
                    <li>Personal information divulgence not caused by reason of Platron.</li>
                </ul>

                <p className="font-normal text-base pb-5">In such cases, we state that we do not accept any responsibility.</p>



                <PdfText title="X. Privacy protection in case of bankruptcy/sale/merger"
                    subtitle="We clearly state that; In cases such as the sale of our company assets, merger with another business institution or bankruptcy filing, the information we collect while the services we provide to you may also be transferred as part of our assets. Any institution to which this information is also assigned as part of the assets, may continue to use the information in compliance with the law and Privacy Policy." />


                <PdfText title="XI. Changes to this policy"
                    subtitle="If any changes are made to this policy, we will post all changes on this page." />

                <PdfText title="XII. How to contact us"
                    subtitle="If you have any questions or requests, please contact us with [info@qizzy.io]." />


            </div>
        </div>

    )


}

export default Privacy;
