import React, { useEffect } from "react";
import { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import c1 from "../images/2.png"
import c2 from "../images/3.png"
import c3 from "../images/4.png"
import c31 from "../images/8.png"
import c32 from "../images/9.png"
import c4 from "../images/10.png"
import c41 from "../images/11.png"
import c42 from "../images/5.png"


const ImageCarousel = (props) => {
    const slides = [
        {
          url: c1,
        },
        {
          url: c2,
        },
        {
          url: c3,
        },
        {
          url: c31,
        },
        {
          url: c32,
        },
    
        {
            url: c4,
        },

        {
          url: c41,
      },

      {
        url: c42,
    }

     
      
      ];

      const [currentIndex, setCurrentIndex] = useState(0);


      

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    props.getCurrentIndex(newIndex)
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    props.getCurrentIndex(newIndex)
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
    this.props.getCurrentIndex(currentIndex)
  };

return(
    <div className='h-[500px] m-auto py-6 relative group dark:bg-gray-900' style={{width: 19+'rem'}}>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className=' h-[540px] rounded-2xl bg-start bg-cover duration-500'
      ></div>
      {/* Left Arrow */}
      <div className=' group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%]  text-2xl rounded-full p-2 bg-black/40 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className=' group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-1 text-2xl rounded-full p-2 bg-black/40 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex top-4 justify-center py-10'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
          >
        
          </div>
        ))}
      </div>
    </div>
    
)

} 

export default ImageCarousel;