import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DropdownComponent() {
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [t, i18next] = useTranslation("global");


    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
        i18next.changeLanguage(e.target.value);
      };
    
    return (
        <div className="relative w-full lg:max-w-sm">
            <select 
             value={selectedLanguage}
             onChange={handleLanguageChange}
            className="p-1 text-gray-800 font-semibold bg-white border rounded-md shadow-sm outline-none appearance-none focus:text-gray-800 w-32 text-center">
                <option value="en">English</option>
                <option value="tr">Türkçe</option>
                <option value="fi">Finnish</option>
            </select>
        </div>
    );
}