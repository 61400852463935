import React, { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'
import logo from '../images/logo.png';
import { useTranslation } from 'react-i18next';
import DropdownComponent from '../components/Dropdown';

const Header = () => {
    const [t, i18next] = useTranslation("global");
    let Links = [
        { name: t("header.head1"), link: "#features" },
        { name: t("header.pricing"), link: "#pricing" },
        { name: t("header.head2"), link: "#contact" },
    ];
    let [open, setOpen] = useState(false);

    return (
        <div className='shadow w-full fixed z-50 top-0 left-0 bg-white dark:text-white dark:bg-gray-900' >
            <div className='flex max-w-screen-xl px-4 justify-between py-4 mx-auto'>

                <a href='#'>
                    <div >
                        <img src={logo} className='h-12 block' alt='logo'></img>
                    </div>
                </a>
                <div>
                    <div onClick={() => setOpen(!open)} className='absolute right-8 top-7 cursor-pointer md:hidden w-7 h-7 '>
                        {
                            open ? <XMarkIcon /> : <Bars3BottomRightIcon />
                        }
                    </div>

                    <ul className={` dark:text-white dark:bg-gray-900 md:flex md:items-center md:pb-0 pb-12 absolute mt-3 md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'} ${open ? 'shadow-lg' : ''}`}>
                        {
                            Links.map((link) => (
                                <li className='md:ml-8 md:my-0 my-7 font-semibold dark:text-white '>
                                    <a href={link.link} className=' hover:text-purple-700 duration-500'>{link.name}</a>
                                </li>))
                        }
                        <li className='md:px-10 px'><DropdownComponent></DropdownComponent></li>

                    </ul>

                </div>
            </div>
        </div>
    );
};

export default Header;