import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import global_en from "./translations/en/global.json"
import global_tr from "./translations/tr/global.json"
import global_fi from "./translations/fi/global.json"
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';


i18next.init({
  interpolation: {escapeValue: false},
  lng: "en",
  resources: {
    en: {
      global: global_en
    },
    tr: {
      global: global_tr
    },
    fi: {
      global: global_fi
    }
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <BrowserRouter>
       <App />
    </BrowserRouter>
    </I18nextProvider>
   
  </React.StrictMode>
);

