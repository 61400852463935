import React from "react";
import logo from '../images/logo.png';
import Fade from 'react-reveal/Fade';
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import GooglePlay from "../components/GooglePlay";
import AppleStore from "../components/AppleStore";
const Footer = () => {
    const [t, i18next] = useTranslation("global");
    let Links = [
        { name: t('footer.privacy'), tag: "/privacy-policy" },
        { name: t('footer.terms'), tag: "/usage" },
        { name: t('footer.faq'), tag: "/#faq" },
    ];
    return (
        <footer className="bg-slate-900 h-fit w-full block">
            <div className="md:flex block py-10 md:px-40 px-10">
                <div className="md:w-5/12 w-full">
                    <Fade> <div className="flex">
                        <img src={logo} alt="logo" className="h-10  "></img>

                    </div ></Fade>
                    <p className="text text-gray-200 py-5">{t('home.subMotto')}</p>

                </div>

                <div className=" md:w-3/12 w-full m-0 items-end md:ml-20 ml-0">
                    <p className="text-white font-bold md:pl-8 text-xl pt-10 md:pb-3 md:p-0">{t('footer.linksHeader')}</p>
                    <Fade> <ul className="block m-0">
                        {
                            Links.map((link) => (
                                <li className='md:ml-8 md:my-0 my-7 font-semibold block text-sm pb-2'>
                                    <a href={link.tag} className='text-sm text-white hover:text-blue-400 duration-500'>{link.name}</a>
                                </li>))
                        }

                    </ul></Fade>
                </div>

                <div className=" md:w-4/12 w-full m-0 items-end md:ml-20 ml-0">
                <h3 className="text-white font-bold text-xl pb-3 ">Get the free app</h3>
                    <div className="block lg:flex md:flex justify-start items-start m-0">
                        
                        <GooglePlay></GooglePlay>
                        <div className="m-2"></div>
                        <AppleStore></AppleStore>

                    </div>
                </div>

            </div>
            <div className="w-full h-5 border-t-2 border-gray-400 my-6"></div>

            <div className=" block md:grid md:grid-cols-2 md:justify-start justify-start md:items-start items-start md:px-40 px-10 pb-10 w-full">
                <p className="text-white text ">
                    © Copyright 2023 Qizzy.io® Qizzy Tech Team.  All rights reserved.
                </p>
                <div className="flex md:items-end md:justify-end justify-center items-center md:pt-0 pt-10">
                    <a target="_blank" href="https://www.instagram.com/qizzyapp/" className="ml-2">
                    <FaInstagram className="text-white text-2xl mr-5" />
                    </a>
                    <a target="_blank" hidden href="https://www.instagram.com/qizzyapp/">
                    <FaTwitter className="text-white text-2xl mr-5" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/qizzy-app/">
                    <FaLinkedin className="text-white text-2xl mr-5" />
                    </a>
                    
                </div>

            </div>

        </footer>
    );

}

export default Footer;