import React from "react";
import PdfText from "./PdfText";

const Usage = () => {
return(
    <div className="w-screen h-full min-h-screen flex flex-col justify-center items-center bg-gray-200 ">
      <div className="h-full w-11/12 lg:p-12 md:w-6/12 min-h-screen shadow-md bg-white p-5 mt-3 mb-3">
      <h1 className="font-bold text-xl pb-12 text-center m-0">Terms of Services</h1>
        <PdfText title="TERMINATION OF SERVICES" 
        subtitle="Qizzy App has the right to limit, suspend, or stop providing the Services to you if you fail to comply with these Terms." />

<PdfText title="COPYRIGHT INFRINGEMENT AND DMCA POLICY" 
        subtitle="As Qizzy App asks others to respect its intellectual property rights, it respects the intellectual property rights of others. Qizzy App deals with copyright infringement in accordance to the Digital Millennium Copyright Act. If you believe that material located on or linked to by Qizzy App.io violates your copyright, you are encouraged to notify Qizzy App. Qizzy App will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. Qizzy App will terminate a visitor’s access to and use of the Site if, under appropriate circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of Qizzy App or others. In the case of such termination, Qizzy App will have no obligation to provide a refund of any amounts previously paid to Qizzy App. The contact information for Qizzy App’s designated agent for receipt of notices of claimed infringement is - questions@Qizzy App.co." />

<PdfText title="INTELLECTUAL PROPERTY" 
        subtitle="You retain ownership of all copyrights and any other intellectual property rights in your Content. Qizzy App (and/or other third parties) retains ownership of intellectual property rights in all content and material other than user Content." />

<p className="font-normal text-base pb-5">By submitting your Content to Qizzy App, you give Qizzy App a non-exclusive worldwide, royalty-free, sublicensable, transferable license to utilize all copyright rights now in existence or that may arise in the future with respect to your Content, in any medium that now exists or may arise in the future, as well as to do anything else that is reasonably appropriate to our Services and its use of your Content (including, but not limited to, use of your name in association with your Content to identify you as the contributor). The license has no restriction as to the medium, dissemination method, type of Service we may offer, or the type of systems or products that may be used in conjunction with your Content.</p>

<p className="font-normal text-base pb-5">This license shall remain in effect for so long as your Content is available on Qizzy App. You are free to remove or delete your Content at any time, in which case this license shall terminate after a commercially reasonable period of time, except for comments, the license to which is perpetual and irrevocable. You understand and agree, however, that even if you delete Content, Qizzy App may retain, but not display or distribute, server copies of your Content.</p>

<p className="font-normal text-base pb-5">You represent that you have all of the necessary rights to grant this license to Qizzy App for all Content you submit.</p>

<p className="font-normal text-base pb-5">Qizzy App grants you a worldwide, non-exclusive, non-sublicensable, and non-transferable license to download, store, view, display, perform, redistribute, and create derivative works of Content solely in connection with your use of, and in accordance with the Terms of, Qizzy App Services.</p>

<PdfText title="DISCLAIMER OF WARRANTIES" 
        subtitle="The Site is provided “as is”. Qizzy App and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Qizzy App nor its suppliers and licensors, makes any warranty that the Site will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Site at your own discretion and risk." />

<PdfText title="LIMITATION OF LIABILITY" 
        subtitle="In no event will Qizzy App, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Qizzy App under this agreement during the twelve (12) month period prior to the cause of action. Qizzy App shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law." />

<PdfText title="GENERAL REPRESENTATION AND WARRANTY" 
        subtitle="You represent and warrant that (i) your use of the Site will be in strict accordance with the privacy section, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside) and (ii) your use of the Site will not infringe or misappropriate the intellectual property rights of any third party." />

<PdfText title="INDEMNIFICATION" 
        subtitle="You agree to indemnify and hold harmless Qizzy App, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Site, including but not limited to your violation of this Agreement" />

<PdfText title="THIS IS A BINDING AGREEMENT" 
        subtitle="By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Site or use any services. If these terms and conditions are considered an offer by Qizzy App, acceptance is expressly limited to these terms." />

<PdfText title="WE CAN CHANGE OUR SERVICES" 
        subtitle="We may change any aspect of the service we want, or even stop it, at any time without giving you notice. We can also terminate or restrict access to it at any time, in our sole discretion. Termination of your access and use of Qizzy App Services shall not relieve you of any obligations arising or accruing before the termination." />

<PdfText title="FEES, PAYMENTS & CANCELLATION POLICY" 
        subtitle="You agree to pay for the Services you use on the Qizzy App Site in accordance the pricing and payment terms presented to you for that service. Fees paid by you are non-refundable." />

<p className="font-normal text-base pb-5">For subscriptions, you will be billed in advance on a recurring cycle for the period you have selected (monthly or annually or quarterly) at the beginning of that period. Your subscription will automatically renew at the end of each period unless you cancel automatic renewal at least 48 hours before your next renewal date.</p>

<p className="font-normal text-base pb-5">Qizzy App may change the fees charged for Services at any time, provided that, for subscription Services, the change will become effective only upon the next renewal date.</p>


<PdfText title="CANCELLATION POLICY" 
        subtitle="Your subscription will automatically renew at the end of each period unless you cancel automatic renewal at least 48 hours before your next renewal date. If you decide to cancel, no refund will be provided for fees paid or past bills and your service will remain active through the end of the existing license period." />

        <p className="font-normal text-base pb-5">At the end of the license period, you will no longer be charged. If you cancel within 48 hours of your next renewal date, the next renewal will go through but that will be your last renewal and the cancellation will take effect on the following renewal as it is required that you cancel at least 48 hours prior to the renewal date. Note: The length of your license can be longer than your billing period for example an annual license, billed semi-annually would mean that a cancellation on month 4 will take effect at the end of the annual license and the second semiannual bill on month 6 will continue as well as the remaining 8 months of the subscription.</p>

        <p className="font-normal text-base pb-5">If you cancel your Qizzy App account, you will lose access to your Qizzy App account and will no longer be able to edit, modify or access the builder, integrations, embed tab, or any analytics information.</p>



<PdfText title="UPGRADES" 
        subtitle="An upgrade is when a paying customer decides to change their current plan to a more expensive paid plan. When upgrading in the middle of a billing period, you will be given a pro-rated credit (not a refund) for the current plan which will be applied to the pro-rated charges for the upgraded plan. At the end of the billing period, the upgraded plan will auto-renew." />

<PdfText title="DOWNGRADES" 
        subtitle="A downgrade is when a paying customer chooses to change their plan from a more expensive to a less expensive (but still paid) plan. When downgrading in the middle of a billing period, your account downgrade will apply at the end of the period and to the following period but will not impact the existing billing period." />


<PdfText title="MISCELLANEOUS" 
        subtitle="This Agreement constitutes the entire agreement between Qizzy App and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of Qizzy App, or by the posting by Qizzy App of a revised version. Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of the Site will be governed by the laws of the state of Delaware, U.S.A. Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by three arbitrators appointed in accordance with such Rules. The arbitration shall take place in Delaware, in the English language and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; Qizzy App may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns." />

        </div>
        </div>

)

}

export default Usage;