import React from "react";
import { Routes, Route } from 'react-router-dom';
import Home from "./layouts/Home";
import SSS from "./components/SSS";
import Privacy from "./components/Privacy";
import Usage from "./components/Usage";


function App() {

  return (
    <>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sss" element={<SSS />} />
          <Route path="/usage" element={<Usage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
       </Routes>
    </>
   
  );
}

export default App;
