import { GooglePlayButton } from "react-mobile-app-button";
import React from "react";

export const GooglePlay = () => {
  const APKUrl =
    "https://play.google.com/store/apps/details?id=com.io.qizzy";
  return (
    <div>
      <GooglePlayButton
        url={APKUrl}
        theme={"dark"}
        width={200}
        className={"custom-style"}
      />
    </div>
  );
};

export default GooglePlay;