import React from "react";



const FeautureCard = (props) => {

    return (
        <>
            <p className="font-bold text-slate-600  text-2xl pb-3 pt-3">
                {props.title}
            </p>
            <p className="font-lg text-base  text-slate-700 pb-3" style={{lineHeight:1.6}}>
                {props.subtitle}
            </p>
        </>

    );

}

export default FeautureCard;