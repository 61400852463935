import React from "react";
import Header from "./Header";
import landingImage from "../images/hero.png";
import qizzyBox from "../images/qizzybox.gif";
import GooglePlay from "../components/GooglePlay";
import AppleStore from "../components/AppleStore";
import Footer from "./Footer";
import FeautureCard from "../components/FeautureCard";
import { FaQuestion, FaMailBulk, FaList, FaStar, FaShieldAlt, FaLanguage, FaPrint } from "react-icons/fa";
import ContactForm from "../components/ContactForm";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import AppText from "../components/AppText";
import ImageCarousel from "../components/ImagesCarousel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Comp1 from "../components/introduction/Comp1";
import Comp2 from "../components/introduction/Comp2";
import Comp3 from "../components/introduction/Comp3";
import Comp4 from "../components/introduction/Comp4";
import Comp5 from "../components/introduction/Comp5";
import SubscribeForm from "../components/Subscribe";
import Pricing from '../components/Pricing';

const Home = () => {
  const [index, setIndex] = useState(0);

  const [t, i18next] = useTranslation("global");


  const introComponents = [<Comp1 />, <Comp1 />, <Comp2 />, <Comp2 />, <Comp2 />, <Comp3 />, <Comp3 />, <Comp3 />,];


  const getImageIndex = (data) => {

    setIndex(data)
  }


  return (<>


    <Header />


    <body className=" overflow-hidden"  >
      <div className="w-screen md:h-10 h-20 " ></div>
      <section class="bg-white dark:bg-gray-900">
        <div class="grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 md:p-8 sm:p-4 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-6 md:py-6 ">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-slate-800 tracking-tight md:text-5xl xl:text-6xl dark:text-white" style={{ letterSpacing: 1.3, lineHeight: 1.1 }}>{t('home.mainMotto')}</h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl text-gray-400 dark:text-white">{t('home.subMotto')}</p>
            <h3 className="dark:text-white font-bold lg:text-xl md:text-xl sm:text-xl text-xl pb-5">{t('home.getApp')}</h3>
            <div className="block lg:flex md:flex justify-start items-start m-0">
              <GooglePlay></GooglePlay>
              <div className="m-2"></div>
              <AppleStore></AppleStore>

            </div>
          </div>
          <div class="hidden lg:mt-3 p-10 lg:col-span-6 lg:block">
            <Fade>
              <img src={landingImage} alt="landing_img" ></img>
            </Fade>
          </div>
        </div>
      </section>
      <section class="bg-white dark:bg-gray-900" id="features">
        <p class="text-center text-lg text-primary/50 font-bold md:text-xl">{t('home.featuresHeader')}</p>
        <p className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center" >{t('home.features')}</p>
        <div class="grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-start lg:col-span-5  lg:my-5 mx-auto">
            <Fade>
              <ImageCarousel getCurrentIndex={getImageIndex} />
            </Fade>
          </div>
          <div class=" lg:mt-3 mt-10 lg:col-span-7 lg:flex">
            {introComponents[index]}
          </div>
        </div>



        <div class="grid max-w-screen-xl  mx-auto lg:py-16    grid-cols-1 p-10 gap-10 md:grid-cols-2 lg:grid-cols-3  px-5">

          <Fade>
            <div className="shadow-lg p-5 rounded-lg text-start bg-slate-50 ">
              <div className="rounded-full bg-yellow-500 w-12 h-12 p-2 text-center items-center">
                <FaQuestion className="m-auto w-8 h-8 text-white " />
              </div>
              <FeautureCard title={t('features.box1.header')} subtitle={t('features.box1.text')}></FeautureCard>

            </div>

          </Fade>
          <Fade>
            <div className="shadow-lg p-5 rounded-lg text-start bg-slate-50">
              <div className="rounded-full bg-purple-700 w-12 h-12 p-2 text-center items-center">
                <FaList className="m-auto w-7 h-7 text-white " />
              </div>
              <FeautureCard title={t('features.box2.header')} subtitle={t('features.box2.text')}></FeautureCard>

            </div>
          </Fade>

          <Fade><div className="shadow-lg p-5 rounded-lg text-start bg-slate-50">
            <div className="rounded-full bg-green-800 w-12 h-12 p-2 text-center items-center">
              <FaStar className="m-auto w-8 h-8 text-white " />
            </div>
            <FeautureCard title={t('features.box3.header')} subtitle={t('features.box3.text')}></FeautureCard>

          </div></Fade>

          <Fade>
            <div className="shadow-lg p-5 rounded-lg text-start bg-slate-50">
              <div className="rounded-full bg-green-800 w-12 h-12 p-2 text-center items-center">
                <FaShieldAlt className="m-auto w-8 h-8 text-white " />
              </div>
              <FeautureCard title={t('features.box4.header')} subtitle={t('features.box4.text')}></FeautureCard>

            </div>

          </Fade>
          <Fade>
            <div className="shadow-lg p-5 rounded-lg text-start bg-slate-50">
              <div className="rounded-full bg-yellow-500 w-12 h-12 p-2 text-center items-center">
                <FaLanguage className="m-auto w-7 h-7 text-white " />
              </div>
              <FeautureCard title={t('features.box5.header')} subtitle={t('features.box5.text')}></FeautureCard>

            </div>
          </Fade>

          <Fade><div className="shadow-lg p-5 rounded-lg text-start bg-slate-50">
            <div className="rounded-full bg-purple-700 w-12 h-12 p-2 text-center items-center">
              <FaPrint className="m-auto w-8 h-8 text-white " />
            </div>
            <FeautureCard title={t('features.box6.header')} subtitle={t('features.box6.text')}></FeautureCard>

          </div></Fade>
        </div>
      </section>
      <section class="bg-white dark:bg-gray-900" id="pricing">
        <Pricing></Pricing>
      </section>
      <section className="bg-white dark:bg-gray-900" id="faq">
        <div
          className="relative w-full  py-10    ring-gray-900/5 sm:mx-auto sm:max-w-7xl px-10">
          <div class="mx-auto">
            <div class="flex flex-col items-center">
              <h2 class="mb-2 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{t('home.faq')}</h2>
              <p class="mt-3 text-lg text-neutral-500  dark:text-white md:text-xl">{t('home.faqSubheader')}

              </p>
            </div>
            <div class="mx-auto mt-8 grid  divide-y divide-neutral-200">
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.1.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-md text-neutral-600 dark:text-white">
                  {t('faq.1.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.2.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.2.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.3.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.3.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.4.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.4.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.5.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.5.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.6.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.6.answer')}
                  </p>
                </details>
              </div>
              <div class="py-5">
                <details class="group">
                  <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                    <p className="font-bold text-slate-600 text-2xl pb-3 pt-3 dark:text-white">{t('faq.7.question')}</p>
                    <span class="transition group-open:rotate-180 dark:text-white">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="group-open:animate-fadeIn mt-3 text-neutral-600 dark:text-white">
                  {t('faq.7.answer')}
                  </p>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-qizzy-pink ">
        <Fade>
          <div className="w-full  text-center pb-10 pt-10" id="contact">

          </div>
        </Fade>

        <div className="grid max-w-screen-xl px-4  mx-auto lg:gap-8 xl:gap-0 pb-10 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-6  pr-5 lg:my-5">
            <Fade>
              <p className="md:text-5xl text-2xl font-bold text-white pb-10 lg:text-start text-center">
              {t('contact.header')}
              </p>
              <h3 className="font lg:text-xl md:text-xl sm:text-xl text-xl text-white pb-5" style={{ lineHeight: 1.5 }}>{t('contact.subtitle')}</h3>
              <div className="py-2 flex items-center justify-start md:px-0 px-8 mt-10">
                <div className=" bg-white rounded w-8 h-8 flex items-center justify-center">
                  <FaMailBulk className="m-auto w-5 h-5 text-qizzy-pink " />
                </div>
                <p className="md:text-xl text-xl text-white pl-2 w-fit text-start">app@qizzy.io</p>
              </div>
            </Fade>
          </div>
          <div className=" lg:mt-3 lg:col-span-6 lg:block lg:px-5">
            <Fade>
              <ContactForm></ContactForm>
            </Fade>
          </div>
        </div>
      </section>



      <Footer />
    </body>
  </>)
}

export default Home;