import React from "react";
import AppText from "../AppText";
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";

const Comp1 = () => {
  const [t, i18next] = useTranslation("global");
    return(<div >
    {}
    <Fade>
      <p className="dark:text-white md:text-3xl text-2xl font-bold text-slate-600  text-black pb-10 pt-10 lg:text-start text-center">
      {t('comp1.header')}
      </p>

      <AppText text={t('comp1.m1')} />
      <AppText text={t('comp1.m2')} />
      <AppText text={t('comp1.m3')} />
      <AppText text={t('comp1.m4')} />
    </Fade>
  </div>)
}


export default Comp1;