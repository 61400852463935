import React from "react";
import { FaCheck, FaClock } from "react-icons/fa";
import { Fade } from "react-reveal";

const AppText = (props) => {
  return (
    <Fade>
      <div className="py-2 flex items-center justify-start md:px-0 px-8">
        {!props.waiting ? 
        <div className=" bg-green-700 rounded-full w-5 h-5 flex items-center justify-center">
          <FaCheck className="text-white w-3 h-3" />
        </div> : 
        <div className=" bg-qizzy-yellow rounded-full w-5 h-5 flex items-center justify-center">
        <FaClock className="text-white w-3 h-3" />
      </div>}
        <p className="dark:text-white md:text-xl text-xl text-slate-700 pl-2 w-fit text-start">{props.text}</p>
      </div>
    </Fade>
  )

}

export default AppText;